import '../Smallcard/smallcard.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
// import { response } from 'express';
// import { response } from 'express';

import {useEffect, useState} from "react";

function Smallcard(){
    const [users, setUsers] = useState({});

    useEffect(() => {
        fetch('https://abiwrite.com/db/news.php')
        .then((response) => response.json())
            .then(data => {
                setUsers(data)
                console.log(data)
            })
          
        .catch(err => console.log(err))
    }, [])



 
    return(
        <div className="card">

            {
                Array.from(users).map(
                    (u, k) => (
                        <a  href={`https://abiwrite.com/single/?id=${u.id}`} key = {u.id}  alt='' >
                            <div className="d-sm-inline-block small-card" >
                                
                                <img src={`https://abiwrite.com/admin/images/${u.image}`} alt="card" />
                                    <h3>{u.heading}</h3>
                                <div className="cat">
                                    {u.categories}
                                </div>
                                

                            </div>
                        </a>

                        
                    )
                )

            }
                   

            {/* <div className="d-sm-inline-block small-card">
                <img src='https://ichef.bbci.co.uk/news/976/cpsprodpb/2bcd/live/58f1e330-2865-11ef-baa7-25d483663b8e.jpg.webp' alt="card" />
                <h3>Greens vow tax hike on wealthier to fund NHS and housing</h3>
                <div className="cat">
                    Politics
                </div>
            </div>
            <div className="d-sm-inline-block small-card">
                <img src='https://ichef.bbci.co.uk/news/976/cpsprodpb/38f4/live/b35a4280-28c5-11ef-acf5-2f465150f8a8.jpg.webp' alt="card" />
                <h3>Gaza ceasefire plan in balance as US says Hamas proposed 'changes'</h3>
                <div className="cat">
                    Politics
                </div>
            </div>
            <div className="d-sm-inline-block small-card">
                <img src='https://ichef.bbci.co.uk/news/976/cpsprodpb/57df/live/354ed5f0-2892-11ef-8be4-eb2649a65fb5.jpg.webp' alt="card" />
                <h3>I went without Sky TV as a child, says Sunak</h3>
                <div className="cat">
                    Politics
                </div>
            </div><br></br> */}

        </div>
    )
}
export default Smallcard;