import './Topnavbar.css';
// import Link from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

function Topnavbar(){
    var logo = require('../images/logo.png');
    // var website = 'www.abiwrite.com';
    return(
        <div className='tnb-container'>

            {/* Header  */}
            <img src={logo} alt='logo' />
            <nav className="navbar navbar-expand-lg bg-body-tertiary">
                <div className="container-fluid">
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo01"
                        aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
{/* 
                    <Link className="navbar-brand" href={website}>Ab<span style="color:red;">i</span>.write</Link> */}

                    <div className="collapse navbar-collapse" id="navbarTogglerDemo01">

                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <a className="nav-link active" aria-current="page" href="#All">Most recent</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link active" aria-current="page" href="#Sport">Sport</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link active" aria-current="page" href="#others">Blogs</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link active" aria-current="page" href="https://www.abiwrite.com/Live">
                                    <span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-camera-video-fill" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M0 5a2 2 0 0 1 2-2h7.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 4.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 13H2a2 2 0 0 1-2-2z"/>
                                </svg>
                                </span>Live TV</a>
                            </li>
                            <li className="nav-item">
                                <a className="btn btn-info" href="https://abiwrite.com/Login/login1.html">Login</a>
                            </li>

                        </ul>
                        <form className="d-flex" role="search">

                            <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search" />
                            <button className="btn btn-outline-success" type="submit">Search</button>
                        </form>
                    </div>
                </div>
            </nav>

        </div>
    )
}
export default Topnavbar;