import './Headline.css';
import Badge from '../Badge/Badge';
import Carousel from '../Carousel/Carousel';
import Smallcard from '../Smallcard/Smallcard';

function Headline(){
    var badgename = 'NEWS HEADLINE'
    return(
       <div className='badge-container'>
            <Badge className='badge' badge ={badgename} />
            <Carousel />
            <Smallcard />
       </div>
    )
}
export default Headline;