import './Horizontalcard.css';

import {useEffect, useState} from "react";

function Horizontalcard(){
   
  const [users, setUsers] = useState({});

  useEffect(() => {
      fetch('https://abiwrite.com/db/all.php')
      .then((response) => response.json())
          .then(data => {
              setUsers(data)
              console.log(data)
          })
        
      .catch(err => console.log(err))
  }, [])


    return(
        <div>
            
            {
                Array.from(users).map(
                    (u, k) => (
                        <a href={`https://abiwrite.com/single/?id=${u.id}`} key = {u.id} alt='' >
                            <div className="hc-container">
                                <img src={`http://abiwrite.com/admin/images/${u.image}`} alt="hc" />
                                <h3>{u.heading}</h3>
                            </div>
                        </a>

                        
                    )
                )

            }


            {/* <div class="hc-container">
                <img src="https://www.naijaloaded.com.ng/wp-content/uploads/2023/06/bolatinubu.jpg" alt="hc" />
                <h3>REALLY!! We’ve Agreed On New Minimum Wage With Labour – Tinubu Assures Nigerians</h3>
            </div>
            <div class="hc-container">
                <img src="https://www.naijaloaded.com.ng/wp-content/uploads/2023/06/Burna-Boy-bet-awards-730x480.jpg" alt="hc" />
                <h3>DO YOU AGREE? Burna Boy May Not Be Skillful Lyrically – Vector</h3>
            </div>
            <div class="hc-container">
                <img src="https://www.naijaloaded.com.ng/wp-content/uploads/2022/08/Solomon-Buchi-7-600x400-1.jpg" alt="hc" />
                <h3>I Am Not Getting A Nigerian Passport For My Children – Solomon Buchi</h3>
            </div>
            <div class="hc-container">
                <img src="https://www.naijaloaded.com.ng/wp-content/uploads/2019/11/6490486d-godwin-emefiele-700x480.jpg" alt="hc" />
                <h3>REALLY!! Emefiele Printed ‘His Own Naira’, Ignored Design Approved By Buhari – Ex-CBN Director</h3>
            </div>
            <div class="hc-container">
                <img src="https://www.naijaloaded.com.ng/wp-content/uploads/2024/03/wizkid-8439.jpg" alt="" />
                <h3>TI’m A Millionaire In Every Currency – Wizkid Says, Marks 13 Years In The Industry</h3>
            </div> */}
        </div>
    )
}
export default Horizontalcard;