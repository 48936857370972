import './badge.css';

function Badge(props){
    return(
        <div className="badge">
            <p>{props.badge}</p>
        </div>
    )
}

export default Badge;