
import './App.css';
import Topnavbar from './components/Header/Topnavbar';
import Headline from './components/Headline/Headline';
import Newpost from './components/Newpost/Newpost';
import Footer from './components/footer/Footer';
import Adlogo from './components/Ad/Adlogo';
// import Single from './components/Single/'

function App() {
  return (
    <div className="App">
      
      <Topnavbar className='topnavbar' />
       <Adlogo />
       <Headline className='headline' />
       <Newpost />
       <Footer />
       
    </div>
  );
}

export default App;
