import './Footer.css';

function Footer(){
    return(
        <div className="footer-container">
            <img src={require('./logo.jpg')} alt="logo" />
            <div className="usefullink">
                <h4>Useful Link</h4>
                <p>Talk Zone</p>
                <p>AW Postcard</p>
                <p>AW Giveaway</p>
                <p>AW Prediction</p>
            </div>
            <div className="info">
                <h4>Informations</h4>
                <p>Advertise on AW</p>
                <p>Write Your Content</p>
                <p>Content Promotion</p>
            </div>
            <div className="contactus">
                <h4>Contact Us</h4>
                <div className="socialmedia">
                    <img src={require("./facebook.png")} alt="fb" />
                    <img src={require("./telegrame.png")} alt="tw" />
                    <img src={require("./instagram.png")} alt="tel" />
                    <img src={require("./tiktok.png")} alt="tik" />
                    <img src={require("./twitter.png")} alt="twi" />
                    <img src={require("./whatsapp.png")} alt="what" />
                    <img src={require("./youtube.png")} alt="you" />

                </div>
            </div>
        </div>
      

        
    )
}
export default Footer;