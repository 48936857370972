import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import '../Carousel/Carousel.css';
// import axios from "axios";
import {useEffect, useState} from "react";
// import { response } from 'express';


function CarouselPage(){

  const [users, setUsers] = useState({});

  useEffect(() => {
      fetch('https://abiwrite.com/db/featurePost.php')
      .then((response) => response.json())
          .then(data => {
              setUsers(data)
              console.log(data)
          })
        
      .catch(err => console.log(err))
  }, [])
  
  return (

    <Carousel className='carousel-container' >
      
        
      {
                Array.from(users).slice(0, 3).map(
                    (u, index) => 
                      (
                      <div className='carousel-content' key={index}>
                        <img src={`http://abiwrite.com/admin/images/${u.image}`} alt='img1' />
                        <a href={`https://abiwrite.com/single/?id=${u.id}`} key = {u.id} alt='' >
                          <p className="legend st" >{u.heading}</p>
                        </a>
                      </div>
                    )
                )

            }


  
        {/* <div className='carousel-content'>
           <img src="https://i0.wp.com/instablog9ja.com/wp-content/uploads/2024/06/IMG_9546.jpeg?w=1200&ssl=1" alt='img2' />
          <p className="legend st">Singer Davido confirms he and chef Chioma are getting married in Lagos on June 25, 2024. 🎥: @tobiadegboyega_</p>
        </div>

        <div className='carousel-content'>
          <img src="https://i0.wp.com/instablog9ja.com/wp-content/uploads/2024/06/IMG_9524.jpeg?w=1200&ssl=1" alt='img3' />
          <p className="legend st">South African lawyer shares his encounter with a couple seeking divorce.</p>
        </div> */}
    </Carousel>
   
  )
}


export default CarouselPage;