import './Newpost.css';
import Badge from '../Badge/Badge';
import Horizontalcard from '../horizontalcard/Horizontalcard';

function Newpost(){
   var badgename = 'NEW POSTS'

    return(
        <div className='newpost-container'>
            <Badge className='badge' badge = {badgename} />
            <Horizontalcard />
        </div>
    )
}
export default Newpost;